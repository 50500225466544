<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">미션 완료 상세보기</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <!-- <div class="mb_30">
          <base-select :options="state" v-model="stateValue" />
        </div> -->
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 158px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">완료일시</td>
            <td class="box_style_02">
              {{ item.registerDate }}
            </td>
            <td class="box_style_02 list_title_style">미션명</td>
            <td class="box_style_02">
              {{ item.mission.name }}
            </td>
            <td class="box_style_02 list_title_style">미션만족도</td>
            <td class="box_style_02">
              {{ item.review.score }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">아이디</td>
            <td class="box_style_02">
              {{ item.loginid }}
            </td>
            <td class="box_style_02 list_title_style">이름</td>
            <td class="box_style_02">
              {{ item.name }}
            </td>
            <td class="box_style_02 list_title_style">이메일</td>
            <td class="box_style_02">{{ item.email }}</td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">수령방법</td>
            <td class="box_style_02">{{ item.receive }}</td>
            <td class="box_style_02 list_title_style">인증전화번호</td>
            <td class="box_style_02">{{ item.authTel }}</td>
            <td class="box_style_02 list_title_style">수령인 이름</td>
            <td class="box_style_02" colspan="3" v-if="item.delivery">
              {{ item.delivery.name }}
            </td>
          </tr>
          <tr class="line_style" v-if="item.delivery">
            <td class="box_style_02 list_title_style">주소</td>
            <td class="box_style_02" colspan="5" style="width: 790px">
              <span> {{ item.delivery.address }} </span>
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">리뷰</td>
            <td
              class="box_style_02"
              colspan="5"
              style="width: 790px; white-space: normal"
            >
              {{ item.review.content }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  created() {
    // this.fetch();
  },
  methods: {},
};
</script>
